@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

.share-button {
    background-color: #2a234a;
    border: none;
    color: #fff;
    font-family: 'Varela Round', sans-serif;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0.5rem 1.5rem;
    border-radius: 0.5rem;
  }
  
  .share-button:hover {
    background-color: #4b3e82;
  }
  
  .share-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .share-button:disabled::after {
    content: '\2714';
    display: inline-block;
    margin-left: 0.5rem;
  }

  .share-button:active:hover {
    background-color: #2d3e50;
  }

  .share-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: transparent;
  }
  
  .share-button:active::after {
    content: '\2714';
    display: inline-block;
    margin-left: 0.5rem;
    animation: checkmark 0.3s ease-in-out;
  }
  
  @keyframes checkmark {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    50% {
      transform: scale(1.2);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  } 

@media screen and (min-width: 200px) {
    .share-button {
        margin-left: 1rem;
    }

    .text-type {
        font-family: 'Varela Round', sans-serif;
        font-size: 1.75rem;
    }
    
    .page-structs {
        background: hsl(216, 12%, 32%);
        min-height: 85vh;
        color: #fff;
        padding: 30px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .words {
        width: 100%;
    }

    .title-style {
        font-family: 'Secular One', sans-serif;
        font-size: 2.2rem;
        padding: 0rem 1rem;
        padding-bottom: 5px;
    }

    .date-style {
        padding: 0rem 1.2rem;
        font-family: 'Varela Round', sans-serif;
    }

    .img-size {
        max-height: 90%;
        max-width: 90%;
    }

    .text-padding {
        padding: 0 1.5rem;
    }
}

@media screen and (min-width: 750px) {
    .share-button {
        margin-left: 0;
    }

    .text-type {
        font-family: 'Varela Round', sans-serif;
        font-size: 1.75rem;
    }
    
    .text-padding {
        padding: 0 5rem;
    }
    
    .title-style {
        font-family: 'Secular One', sans-serif;
        font-size: 2.7rem;
        padding: 0.5rem 0rem;
    }
    
    .date-style {
        color: #DDDDDD;
        font-family: 'Varela Round', sans-serif;
        padding: 0rem 0rem;
    }
    
    .words {
        width: 80%;
    }
    
    .img-size {
        max-height: 80%;
        max-width: 80%;
    }
    
    .page-structs {
        background: hsl(216, 12%, 32%);
        min-height: 85vh;
        color: #fff;
        padding: 30px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

/* iPads */
@media screen and (min-width: 1400px) {
    .text-type {
        font-family: 'Varela Round', sans-serif;
        font-size: 1.75rem;
    }
    
    .text-padding {
        padding: 0 22.5rem;
    }
    
    .title-style {
        font-family: 'Secular One', sans-serif;
        font-size: 2.7rem;
        padding: 0.5rem 0;
    }
    
    .date-style {
        color: #DDDDDD;
        font-family: 'Varela Round', sans-serif;
    }
    
    .words {
        width: 50%;
    }
    
    .img-size {
        max-height: 50%;
        max-width: 50%;
    }
    
    .page-structs {
        background: hsl(216, 12%, 32%);
        min-height: 85vh;
        color: #fff;
        padding: 30px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (min-width: 1500px) {
    .text-padding {
        padding: 0 30rem;
    }     

    .date-style {
        color: #DDDDDD;
        font-family: 'Varela Round', sans-serif;
        padding-bottom: 0;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');

.navbar {
  height: 80px;
  display: flex;
  font-size: 1.2rem;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  list-style: none;
  text-align: center;
  justify-content: end;
  font-family: 'Secular One', sans-serif;
}

.nav-item {
  display: flex;
  align-items: center;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-family: 'Secular One', sans-serif;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.header {
  font-size: 2.5rem;
  font-family: 'Secular One', sans-serif;
}

.logo-width {
  width: 25px;
}

@media screen and (max-width: 1100px) {
  .header {
    font-size: 2.7rem; 
    width: 80%;
    padding-left: 20px;
  }

  .logo-width {
    width: 20px;
  }

  .NavbarItems {
    position: relative;
  }

  .nav-links:hover {
    color: #1888ff;
    border-radius: 0;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: 60px;
    height: 50vh;
    position: absolute;
    padding-bottom: 100px;
    align-items: center;
    top: 229px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-80%, 190%);
    font-size: 2.5rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2.5rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    color: #fff;
    font-size: 0.5rem;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

.card {
    background-color: hsl(216, 20%, 31%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.card-spotlight {
    background-color: hsl(216, 20%, 31%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.card-spotlight img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.card img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.card-content {
    padding: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
    color: white;
    text-align: left;
    font-family: 'Secular One', sans-serif;
}

.date {
    font-family: 'Varela Round', sans-serif;
}

@media screen and (max-width: 990px) {
    .card {
        background-color: hsl(216, 20%, 31%);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        overflow: auto;
    }

    .title {
        font-size: 3.75rem;
        padding: 0.5rem 0;
        max-height: 100%;
        max-width: 100%;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

.text {
    text-align: left;
    padding: 2.5% 10%;
    padding-bottom: 1%;
    font-family: 'Varela Round', sans-serif;
    font-size: 1.75rem;
}

.page-struct {
    background: hsl(216, 12%, 32%);
    height: 80vh;
    color: #fff;
}

a {
    color: #5ebeff;
    font-family: 'Varela Round', sans-serif;
    text-decoration: underline;
}

a:hover {
    color: #2077b1;
}

@media screen and (max-width: 1224px) {
    .text {
        text-align: left;
        padding: 10% 10%;
        padding-bottom: 2%;
        font-family: 'Varela Round', sans-serif;
        font-size: 1.75rem;
    }

    .page-struct {
        height: 170vh;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

.font-details {
    font-family: 'Varela Round', sans-serif;
}

.footer {
    background: hsl(216, 12%, 32%);
    color: #fff;
    text-align: center;
    bottom: 0;
    width: 100%;
    height: 10rem;
    padding-top: 40px;
}

@media screen and (max-width: 1100px) {
    .footer {
        padding-top: 40px;
        padding-bottom: 30px;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

.preamble {
    font-family: 'Varela Round', sans-serif;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.preamble-container {
    padding: 0.25rem;
}

.page-format {
    background: hsl(216, 12%, 32%);
    color: white;
    min-height: 85vh;
}

.header-format {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
}

.spotlight-map {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-auto-rows: minmax(200px, auto);
    grid-gap: 2rem;
    justify-items: center;
    align-items: center;
    margin: 1rem;
}

.map {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
    grid-auto-rows: minmax(200px, auto);
    grid-gap: 3rem;
    justify-items: center;
    align-items: center;
    padding: 20px 60px;
}

@media screen and (max-width: 1100px) {
    .preamble {
        justify-content: left;
        padding-bottom: 5px;
    }

    .spotlight-map {
        padding-bottom: 1rem;
    }

    .map {
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        grid-gap: 2rem;
        padding: 0;
        margin-top: 1rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .preamble-container {
        padding: 0;
    }

    .intro {
        font-weight: normal;
        font-size: 2.5rem;
    }
}